import { Controller } from '@hotwired/stimulus';
import { useStore } from 'stimulus-store';
import { Crisp } from 'crisp-sdk-web';

import globalStore from 'stores/global_store';

export default class extends Controller {
  static stores = [globalStore];

  static values = {
    id: String,
    userId: String,
    email: String,
    nickname: String,
    plan: String,
  };

  connect() {
    useStore(this);

    if (!this.globalStoreValue.crispInitialized) {
      Crisp.configure(this.idValue);

      Crisp.chat.hide();

      Crisp.user.setEmail(this.emailValue);
      Crisp.user.setNickname(this.nicknameValue);

      Crisp.session.setData({
        user_id: this.userIdValue,
        plan: this.planValue,
      });

      this.setGlobalStoreValue({
        ...this.globalStoreValue,
        crispInitialized: true,
      });

      // Callback to hide chat completetely when crisp X button is clicked
      Crisp.chat.onChatClosed(() => {
        Crisp.chat.hide();
      })

      // This hides the chat whenever navigating to a new page without a full page reload
      document.addEventListener('turbo:visit', () => {
        Crisp.chat.hide();
      });
    }
  }

  toggleCrispChat() {
    const isVisible = Crisp.chat.isVisible();

    if (isVisible) {
      // If chat is visible, close and hide it
      Crisp.chat.close();
      Crisp.chat.hide();
    } else {
      // If chat is not visible, show and open it
      Crisp.chat.show();
      Crisp.chat.open();
    }
  }
}
