import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['destroyInput'];

  onDeleteClick() {
    if (this.element.dataset.persisted === 'true') {
      this.element.style.display = 'none';
      this.destroyInputTarget.value = '1';
    } else {
      this.element.remove();
    }
  }

  onTypeChange(e) {
    const form = e.target.closest('form');
    const actionInput = form.querySelector('input[name="view[action]"]');

    actionInput.value = 'rerender_form';

    form.requestSubmit();
  }
}
