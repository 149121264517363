import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['codeSymbolInput', 'codeInput'];

  connect() {
    if (window.location.search.includes('?user_data')) {
      window.history.pushState(
        {},
        document.title,
        `${window.location.origin}${window.location.pathname}`
      );
    }
  }

  inputCodeSymbol(e) {
    const index = parseInt(e.target.dataset.index);

    if (e.target.value.length === 0) {
      if (this.codeSymbolInputTargets[index - 1]) {
        this.codeSymbolInputTargets[index - 1].focus();
      }
    } else {
      if (this.codeSymbolInputTargets[index + 1]) {
        this.codeSymbolInputTargets[index + 1].focus();
      }
    }

    this.codeInputTarget.value = this.codeSymbolInputTargets
      .map((el) => el.value)
      .join('');
  }

  pasteCode(e) {
    const code = e.clipboardData.getData('Text');

    if (code) {
      [...code.slice(0, 6)].forEach((symbol, index) => {
        if (this.codeSymbolInputTargets[index]) {
          this.codeSymbolInputTargets[index].value = symbol;
        }
      });
    }
  }
}
