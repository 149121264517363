import { Controller } from '@hotwired/stimulus';
import { initFlowbite } from 'flowbite';

export default class extends Controller {
  static targets = ['actionInput'];

  connect() {
    initFlowbite();
  }

  onNewClick(e) {
    this.actionInputTarget.value = 'add_view';
    this.element.requestSubmit();
  }
}
