import { Controller } from '@hotwired/stimulus';
import { initFlowbite } from 'flowbite';

export default class extends Controller {
  static targets = ['action', 'container'];

  connect() {
    initFlowbite();
    $(this.containerTarget)
      .sortable({
        axis: "y",
        update: (event, ui) => {
          const elements = this.element.querySelectorAll('.property-order-input');
          elements.forEach((el, index) => {
            el.value = index;
          });
        }
      });
  }

  rerender() {
    this.actionTarget.value = 'rerender';
    this.element.requestSubmit();
  }

  onNewClick() {
    this.actionTarget.value = 'add_entity';
    this.element.requestSubmit();
  }
}
