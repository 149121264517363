import { Controller } from '@hotwired/stimulus';
import { useStore } from 'stimulus-store';
import mixpanel from 'mixpanel-browser';

import globalStore from 'stores/global_store';

export default class extends Controller {
  static stores = [globalStore];

  static values = {
    token: String,
    userId: String,
  };

  connect() {
    useStore(this);

    if (!this.globalStoreValue.mixpanelInitialized) {
      mixpanel.init(this.tokenValue, {
        track_pageview: false,
        persistence: 'localStorage',
      });

      mixpanel.identify(this.userIdValue);

      this.setGlobalStoreValue({
        ...this.globalStoreValue,
        mixpanelInitialized: true,
      })
    }
  }
}
