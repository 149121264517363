import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  onEditClick(e) {
    e.preventDefault();
  
    if (this.inputTarget.disabled) {
      this.inputTarget.value = '';
      this.inputTarget.disabled = false;
      this.inputTarget.focus();
    } else {
      this.inputTarget.value = '*********';
      this.inputTarget.disabled = true;
    }
  }
}
