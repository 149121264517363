import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select'];

  static values = {
    propertyId: String,
    propertyEntityId: String,
  }

  connect() {
    // Extract projectId and entityId from the current URL
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split('/');
    const projectId = pathParts[2];

    // Construct the new URL
    const newUrl = `/projects/${projectId}/entities/${this.propertyEntityIdValue}/datasets/references`;

    $(this.selectTarget).select2({
      placeholder: 'Select a reference',
      ajax: {
        url: newUrl,
        dataType: 'json',
        delay: 250,
        data: (params) => {
          return {
            property_id: this.propertyIdValue,
            query: params.term,
          };
        },
        processResults: function (data) {
          return {
            results: data.map(item => ({ id: item.id, text: item.label }))
          };
        },
        cache: true
      },
      minimumInputLength: 1,
    });
  }
}
