import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.frame = document.getElementById('designer-frame');
    window.RED = this.frame.contentWindow.RED;

    this.onEvent = (evt) => {
      if (evt.data.type === 'workflows.save_response') {
        this.element.classList.remove('pointer-events-none');
      }
    };

    window.addEventListener('message', this.onEvent, false);
  }

  disconnect() {
    window.removeEventListener('message', this.onEvent);
  }

  save(e) {
    e.preventDefault();

    this.element.classList.add('pointer-events-none');

    this.sendEventToDesigner('workflows.deploy');
  }

  sendEventToDesigner(type, content = {}) {
    this.frame.contentWindow.postMessage({ type, content }, window.location.origin);
  }
}
