import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  submitForm(event) {
    event.preventDefault();

    const { formId } = event.currentTarget.dataset;

    const form = document.getElementById(formId);

    if (form) {
      form.submit();
    }
  }
}
