import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const forms = document.getElementsByClassName('simple_form');

    if (forms.length > 0) {
      for (let form of forms) {
        if (!(form.dataset.controller && form.dataset.controller.includes('forms'))) {
          form.setAttribute('data-controller', 'forms');
        }
      }
    }
  }
}
