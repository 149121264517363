import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['destroy', 'heading', 'collapsed'];

  onDeleteClick(e) {
    this.element.style.display = 'none';
    this.destroyTarget.value = '1';
  }

  onNameInput(e) {
    this.headingTarget.textContent = e.target.value;
  }

  toggle() {
    const switchedValue = this.collapsedTarget.value === "true" ? "false" : "true";
    this.collapsedTarget.value = switchedValue;
  }
}
