import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['editButton', 'form', 'cardElement', 'hiddenForm', 'cardTokenInput', 'cardDetails'];

  connect() {
    this.loadScript();
  }

  onFormSubmit(event) {
    event.preventDefault();

    this.stripe.createToken(this.cardElement).then((result) => {
      if (result.error) {
        alert(result.error.message);
      } else {
        this.cardTokenInputTarget.value = result.token.id;
        this.hiddenFormTarget.requestSubmit();
      }
    });
  }

  onEditButtonClick() {
    this.editButtonTarget.classList.add('hidden');
    this.cardElement.mount(this.cardElementTarget);
    this.formTarget.classList.remove('hidden');
    this.cardDetailsTarget.classList.add('hidden');
  }

  loadScript() {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.onload = () => {
      this.stripe = Stripe(this.element.dataset.pk);
      this.elements = this.stripe.elements();

      const style = {
          base: {
              color: '#ffffff', // text color
              fontFamily: 'sans-serif',
              fontSmoothing: 'antialiased',
              '::placeholder': {
                  color: '#9CA3AF' // placeholder color
              },
              iconColor: '#ffffff' // icon color
          },
          invalid: {
              color: '#FFC7EE' // invalid text color
          }
      };

      this.cardElement = this.elements.create('card', { style });
    };

    document.body.appendChild(script);
  }
}
