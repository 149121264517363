import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select'];

  connect() {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split('/');
    const projectId = pathParts[2];

    // Construct the new URL
    const newUrl = `/projects/${projectId}/stored_files`;

    $(this.selectTarget).select2({
      placeholder: 'Select a file',
      ajax: {
        url: newUrl,
        dataType: 'json',
        delay: 250,
        data: (params) => {
          return {
            query: params.term,
          };
        },
        processResults: function (data) {
          return {
            results: data.records.map(item => ({ id: item.id, text: item.name }))
          };
        },
        cache: true
      },
      minimumInputLength: 1,
    });
  }
}
