import { Controller } from '@hotwired/stimulus';
import initializeCodeMirror from 'utils/initialize_code_mirror';

export default class extends Controller {
  static targets = ['source', 'sourceEditor'];

  connect() {
    initializeCodeMirror({
      parent: this.sourceEditorTarget,
      sourceTarget: this.sourceTarget,
      language: 'liquid',
    });
  }
}
