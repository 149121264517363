import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["wideSidebar", "narrowSidebar"]

  foldSidebar() {
    this.modifyPage(true);
    this.sendRequest(true);
  }

  unfoldSidebar() {
    this.modifyPage(false);
    this.sendRequest(false);
  }

  modifyPage(isSidebarCollapsed) {
    const main = document.getElementById("main")
    const navbar = document.getElementById("navbar")

    if (isSidebarCollapsed) {
      navbar.classList.replace("md:ml-64", "md:ml-16")
      main.classList.replace("md:ml-64", "md:ml-16")
      this.wideSidebarTarget.classList.add("hidden")
      this.narrowSidebarTarget.classList.remove("hidden")
    } else {
      navbar.classList.replace("md:ml-16", "md:ml-64")
      main.classList.replace("md:ml-16", "md:ml-64")
      this.wideSidebarTarget.classList.remove("hidden")
      this.narrowSidebarTarget.classList.add("hidden")
    }
  }

  sendRequest(isSidebarCollapsed) {
    const url = '/profile/session_config';
    const requestData = {
      profile: {
        sidebar_collapsed: isSidebarCollapsed,
      }
    };
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    };
    fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(requestData),
    })
  }
}
