import { Controller } from '@hotwired/stimulus';
import JSONEditor from 'jsoneditor';

export default class extends Controller {
  static targets = ["inputView", "outputView"];

  static values = {
    input: {
      type: Object,
      default: {},
    },
    output: {
      type: Object,
      default: {},
    },
  };

  connect() {
    const inputEditor = new JSONEditor(this.inputViewTarget, {
      mode: 'view',
    });

    inputEditor.set(this.inputValue);

    const outputEditor = new JSONEditor(this.outputViewTarget, {
      mode: 'view',
    });

    outputEditor.set(this.outputValue);
  }
}
