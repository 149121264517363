import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    refreshable: { type: Boolean, default: false },
    refreshPeriod: { type: Number },
    turboFrameId: { type: String },
  };

  connect() {
    if (this.refreshableValue) {
      this.refreshInterval = setInterval(() => {
        const frame = document.getElementById(this.turboFrameIdValue);

        if (frame) {
          frame.reload();
        }
      }, this.refreshPeriodValue);
    }
  }

  disconnect() {
    this.stopAutoRefresh();
  }

  stopAutoRefresh() {
    if (this.refreshableValue) {
      clearInterval(this.refreshInterval);
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  close(event) {
    this.stopAutoRefresh();
    this.element.remove();

    const frame = document.getElementById('application-modal')

    frame.removeAttribute('src');
  }
}
