import { Controller } from '@hotwired/stimulus';
import { EditorView, basicSetup } from 'codemirror';
import { EditorState, Compartment } from '@codemirror/state';
import { liquid } from '@codemirror/lang-liquid';
import { html } from '@codemirror/lang-html';

import { dracula } from 'thememirror';

const tabSize = new Compartment;

export default class extends Controller {
  static targets = ['source', 'editor'];

  connect() {
    const parent = this.editorTarget;

    const state = EditorState.create({
      doc: this.sourceTarget.value,
      extensions: [
        basicSetup,
        dracula,
        html({ base: liquid() }),
        tabSize.of(EditorState.tabSize.of(2)),
        EditorView.updateListener.of((value) => {
          this.sourceTarget.value = value.state.doc.toString();
        }),
      ],
    });

    new EditorView({ parent, state });
  }
}
