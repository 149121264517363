import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["name", "form"];

  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this));
    document.addEventListener('keydown', this.handleEscKey.bind(this));

    const iframe = document.getElementById('designer-frame');
    if (iframe) {
      iframe.addEventListener('load', this.handleIframeLoad.bind(this));
    }
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
    document.removeEventListener('keydown', this.handleEscKey.bind(this));

    const iframe = document.getElementById('designer-frame');
    if (iframe) {
      iframe.removeEventListener('load', this.handleIframeLoad.bind(this));
      iframe.contentWindow.removeEventListener('click', this.handleIframeClick.bind(this));
    }
  }

  handleIframeLoad() {
    const iframe = document.getElementById('designer-frame');
    iframe.contentWindow.addEventListener('click', this.handleIframeClick.bind(this));
  }

  handleIframeClick(event) {
    if (this.formTarget.style.display === 'block' && !this.formTarget.contains(event.target)) {
      this.showName();
    }
  }

  handleClickOutside(event) {
    if (this.formTarget.style.display === 'block' && !this.formTarget.contains(event.target)) {
      this.showName();
    }
  }

  handleEscKey(event) {
    if (this.formTarget.style.display === 'block' && event.key === 'Escape') {
      this.showName();
    }
  }

  showForm(event) {
    event.stopPropagation();
    this.formTarget.elements.name.value = this.nameTarget.innerHTML;
    this.nameTarget.style.display = 'none';
    this.formTarget.style.display = 'block';

    // Set focus and place cursor at the end of the line
    const nameInput = this.formTarget.elements.name;
    nameInput.focus();
    nameInput.setSelectionRange(nameInput.value.length, nameInput.value.length);

  }

  showName() {
    this.nameTarget.style.display = 'block';
    this.formTarget.style.display = 'none';
  }

  updateWorkflowName(event) {
    event.preventDefault();

    const newName = this.formTarget.elements.name.value;

    // Get the current URL and remove '/design' from the end
    const url = window.location.pathname.replace('/design', '');

    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ workflow: { name: newName } }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => {
      if (response.ok) {
        this.nameTarget.innerHTML = newName;
        this.showName();
        document.title = `Edit ${newName}`;
      } else {
        alert('This name has already been taken. Please choose another.');
        this.showName();
      }
    });
  }
}
