import { Controller } from '@hotwired/stimulus';

import { EditorView } from 'codemirror';
import { EditorState } from '@codemirror/state';
import { json } from '@codemirror/lang-json';

import { dracula } from 'thememirror';

export default class extends Controller {
  static targets = [
    'button',
    'inputRow',
    'outputRow',
    'inputView',
    'outputView',
  ];

  static values = {
    toggled: { type: Boolean, default: false },
  };

  connect() {
    this.inputEditorView = null;
    this.outputEditorView = null;
  }

  toggle() {
    const [downIcon, upIcon] = this.buttonTarget.getElementsByTagName('svg');

    if (this.toggledValue) {
      downIcon.classList.remove('hidden');
      upIcon.classList.add('hidden');

      this.inputRowTarget.classList.add('hidden');
      this.outputRowTarget.classList.add('hidden');
    } else {
      this.fetchExecution();

      downIcon.classList.add('hidden');
      upIcon.classList.remove('hidden');

      this.inputRowTarget.classList.remove('hidden');
      this.outputRowTarget.classList.remove('hidden');
    }

    this.toggledValue = !this.toggledValue;
  }

  renderJsonView(parent, doc, editorView) {
    const customThemeExtension = EditorView.theme({
      ".cm-scroller": {
        backgroundColor: "#101727",
      },
    });

    const state = EditorState.create({
      doc: JSON.stringify(doc),
      readOnly: true,
      extensions: [
        json(),
        EditorState.readOnly.of(true),
        // EditorView.editable.of(false),
        dracula,
        customThemeExtension,
      ],
    });

    if (editorView) {
      editorView.setState(state);
    } else {
      editorView = new EditorView({ parent, state });
    }

    return editorView;
  }

  async fetchExecution() {
    const { workflowId, executionId } = this.element.dataset;

    const response = await fetch(`/workflows/${workflowId}/executions/${executionId}`);

    const data = await response.json();

    this.inputEditorView = this.renderJsonView(this.inputViewTarget, data.input, this.inputEditorView);
    this.outputEditorView = this.renderJsonView(this.outputViewTarget, data.output, this.outputEditorView);
  }
}