  import { createStore } from 'stimulus-store';

  const globalStore = createStore({
    name: 'globalStore',
    type: Object,
    initialValue: {
      crispInitialized: false,
      mixpanelInitialized: false,
    },
  });

export default globalStore;
