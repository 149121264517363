import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['intervalSwitch', 'monthPrice', 'yearPrice'];

  onIntervalSwitchClick() {
    if (this.intervalSwitchTarget.checked) {
      this.monthPriceTargets.forEach((target) => {
        target.classList.add('hidden');
      });
      this.yearPriceTargets.forEach((target) => {
        target.classList.remove('hidden');
      });
    } else {
      this.yearPriceTargets.forEach((target) => {
        target.classList.add('hidden');
      });
      this.monthPriceTargets.forEach((target) => {
        target.classList.remove('hidden');
      });
    }
  }
}
