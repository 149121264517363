import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'messagesContainer',
    'message',
  ];

  messageTargetConnected() {
    this.messagesContainerTarget.scrollTop = this.messagesContainerTarget.scrollHeight;
  }
}
