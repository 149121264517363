import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['applicationModal'];

  connect() {
    this.onBeforeFetchRequest = function(e) {
      if (e.srcElement.id === 'application-modal') {
        this.showModalLoader();
      }
    };

    document.addEventListener('turbo:before-fetch-request', this.onBeforeFetchRequest.bind(this));

    this.onBeforeFrameRender = function(e) {
      if (e.srcElement.id === 'application-modal') {
        this.hideModalLoader();
      }
    };

    document.addEventListener('turbo:before-frame-render', this.onBeforeFrameRender.bind(this));
  };

  disconnect() {
    document.removeEventListener('turbo:before-fetch-request', this.onBeforeFetchRequest);
    document.removeEventListener('turbo:before-frame-render', this.onBeforeFrameRender);
  }

  showModalLoader() {
    this.applicationModalTarget.classList.remove('hidden');
  }

  hideModalLoader() {
    this.applicationModalTarget.classList.add('hidden');
  }
}
