import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select'];

  connect() {
    if (this.selectTargets.length > 0) {
      this.connectSelect2();
    }
  }

  disconnect() {
    if (this.selectTargets.length > 0) {
      this.disconnectSelect2();
    }
  }

  connectSelect2() {
    this.selectTargets.forEach((target) => {
      const el = $(target);

      el.select2();

      if (target.dataset.action) {
        const [evt, funcRef] = 'change->forms#refreshForm'.split('->');

        if (evt === 'change') {
          const [controllerName, action] = funcRef.split('#');

          if (controllerName === 'forms') {
            const that = this;
            el.on(evt, function(e) { that[action](e) });
          }
        }
      }
    });
  }

  disconnectSelect2() {
    this.selectTargets.forEach((target) => {
      $(target).select2('destroy').remove();
    });
  }

  refreshForm() {
    this.element.action = `${this.element.action}?rerender=true`;
    this.element.requestSubmit();
  }
}
