import { Controller } from '@hotwired/stimulus';
import { render } from 'preact';
import { useMemo, useState, useCallback } from 'preact/hooks';
import MiniSearch from 'minisearch';

const SearchComponent = ({ pages }) => {
  const [results, setResults] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isResultsHovered, setisResultsHovered] = useState(false);

  const miniSearch = useMemo(() => {
    const miniSearch = new MiniSearch({
      fields: ['name', 'description'],
      storeFields: ['name', 'path'],
    });

    miniSearch.addAll(pages);

    return miniSearch;
  }, [pages]);

  const onQueryInput = useCallback((e) => {
    setResults(miniSearch.search(e.target.value));
  }, [miniSearch, setResults]);

  const toggleFocus = useCallback(() => {
    if (!isResultsHovered) {
      setIsInputFocused(previsInputFocused => !previsInputFocused);
    }
  }, [isResultsHovered, setIsInputFocused]);

  return (
    <div class="w-full max-w-screen-xl">
      <div class="flex justify-center">
        <div class="w-full max-w-md">
          <div class="bg-gray-50 dark:bg-gray-900 shadow-md rounded-lg px-3 py-2">
            <div class="flex items-center bg-gray-200 rounded-md">
              <input
                class="w-full rounded-md bg-gray-50 dark:bg-gray-700 text-white leading-tight focus:outline-none py-2 px-2"
                id="search"
                type="text"
                placeholder="Search documentation"
                onInput={onQueryInput}
                onFocus={toggleFocus}
                onBlur={toggleFocus}
              />
            </div>

            {(results.length > 0 && isInputFocused) && (
              <div
                style="margin-right: 25px;"
                class="max-h-48 overflow-y-auto py-3 text-sm bg-gray-700 rounded-lg fixed z-50"
                onMouseEnter={() => setisResultsHovered(true)}
                onMouseLeave={() => setisResultsHovered(false)}
              >
                {results.map((result) => (
                  <a href={result.path} >
                    <div class="flex justify-start cursor-pointer text-white dark:hover:bg-gray-600 rounded-md px-2 py-2 my-2">
                      <div class="flex-grow font-medium px-2">
                        {result.name}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default class extends Controller {
  static targets = ['content'];

  static values = {
    pages: {
      type: Array,
      default: [],
    }
  };

  connect() {
    render((
      <SearchComponent
        pages={this.pagesValue}
      />
    ), this.contentTarget);
  }

  disconnect() {
    render(null, this.contentTarget);
  }
}
