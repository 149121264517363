import { Controller } from '@hotwired/stimulus';
import initializeCodeMirror from 'utils/initialize_code_mirror';

export default class extends Controller {
  static targets = ['source', 'editor'];

  connect() {
    initializeCodeMirror({
      parent: this.editorTarget,
      sourceTarget: this.sourceTarget,
      language: 'liquid',
    });
  }
}
